import { useState } from 'react';
import {
    Button,
    FormControl,
    FormLabel,
    InputLabel,
    Input,
    Card,
    CardContent,
    CardActions,
    CardHeader,
    IconButton,
    InputAdornment,
    TextField,
    FormControlLabel,
    Radio,
    RadioGroup,
    Select,
    MenuItem,
} from '@mui/material';
import 'dayjs/locale/pl';
import { bookSlot } from '../services/bookingService';
import CloseIcon from '@mui/icons-material/Close';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    maxHeight: '90vh',
    overflowY: 'auto',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export const BookingForm = ({ slot, onSubmit }) => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [place, setPlace] = useState('');
    const [notes, setNotes] = useState('');
    const [age, setAge] = useState('');
    const [eventType, setEventType] = useState('');
    const [city, setCity] = useState('');
    const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
    const phonePrefix = '+48';

    async function handleSubmit(event) {
        event.preventDefault();
        setSubmitButtonDisabled(true);
        try {
            const response = await bookSlot({
                firstName,
                lastName,
                email,
                phone,
                place,
                notes,
                startDate: slot.day,
                profileId: null,
                startHour: slot.day.hour(),
                age,
                eventType,
                city,
            });
            onSubmit({ success: response });
        } catch (error) {
            onSubmit({ error });
        } finally {
            setSubmitButtonDisabled(false);
        }
    }

    const handleEventType = (e) => {
        setEventType(e.target.value);
        if (e.target.value === 'Online') setCity('');
    };

    return (
        <form onSubmit={handleSubmit}>
            <Card sx={style}>
                <CardHeader
                    title={slot?.day.locale('pl').format('dddd, D MMMM YYYY, HH:mm')}
                    action={
                        <IconButton aria-label="close" onClick={onSubmit}>
                            <CloseIcon />
                        </IconButton>
                    }
                />
                <CardContent>
                    <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                        <InputLabel required htmlFor="first-name">
                            Imię
                        </InputLabel>
                        <Input required id="first-name" onChange={(e) => setFirstName(e.target.value)} />
                    </FormControl>
                    <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                        <InputLabel required htmlFor="last-name">
                            Nazwisko
                        </InputLabel>
                        <Input required id="last-name" onChange={(e) => setLastName(e.target.value)} />
                    </FormControl>
                    <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                        <InputLabel required htmlFor="email">
                            Email
                        </InputLabel>
                        <Input required id="email" type="email" onChange={(e) => setEmail(e.target.value)} />
                    </FormControl>
                    <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                        <InputLabel required htmlFor="phone">
                            Telefon
                        </InputLabel>
                        <Input
                            required
                            id="phone"
                            type="tel"
                            inputProps={{
                                maxLength: 9,
                                minLength: 9,
                                pattern: '[0-9]*',
                            }}
                            startAdornment={<InputAdornment position="start">{phonePrefix}</InputAdornment>}
                            onChange={(e) => setPhone(`${phonePrefix}${e.target.value}`)}
                        />
                    </FormControl>
                    <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                        <InputLabel required htmlFor="place">
                            Placówka
                        </InputLabel>
                        <Input required id="place" onChange={(e) => setPlace(e.target.value)} />
                    </FormControl>
                    <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                        <InputLabel required htmlFor="age">
                            Wiek Uczestników/Klasa
                        </InputLabel>
                        <Input required id="age" onChange={(e) => setAge(e.target.value)} />
                    </FormControl>
                    <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                        <FormLabel id="event-type">Typ Wydarzenia</FormLabel>
                        <RadioGroup
                            aria-labelledby="event-type"
                            row
                            name="event-type"
                            required
                            onChange={handleEventType}
                        >
                            <FormControlLabel value="Online" control={<Radio />} label="Online" />
                            <FormControlLabel value="Stacjonarnie" control={<Radio />} label="Stacjonarnie" />
                        </RadioGroup>
                    </FormControl>
                    {eventType === 'Stacjonarnie' && (
                        <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                            <InputLabel id="city">Miasto</InputLabel>
                            <Select
                                id="city-select"
                                labelId="city"
                                required={eventType === 'Stacjonarnie'}
                                value={city}
                                label="Miasto"
                                onChange={(e) => setCity(e.target.value)}
                            >
                                <MenuItem value="Kraków">Kraków</MenuItem>
                                <MenuItem value="Wrocław">Wrocław</MenuItem>
                            </Select>
                        </FormControl>
                    )}
                    <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                        <TextField
                            id="notes"
                            onChange={(e) => setNotes(e.target.value)}
                            multiline
                            rows={3}
                            placeholder="Uwagi"
                            variant="standard"
                        />
                    </FormControl>
                </CardContent>
                <CardActions>
                    <Button type="submit" variant="contained" disabled={submitButtonDisabled}>
                        Rezerwuj
                    </Button>
                </CardActions>
            </Card>
        </form>
    );
};
