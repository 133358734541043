import styles from './BookingPage.module.scss';
import Button from '@mui/material/Button';
import { createSearchParams } from 'react-router-dom';
import { getAvailableSlotsUrl, availableSlotsWeekStartParam } from '../../constants';
import { Spinner } from '../../common/Spinner';
import useFetch from '../../services/useFetch';
import { useMemo, useState } from 'react';
import dayjs from 'dayjs';
import _ from 'lodash';
import updateLocale from 'dayjs/plugin/updateLocale';
import { Modal, Dialog, DialogTitle, DialogContent, DialogActions, ButtonGroup } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { BookingForm } from '../booking-form/BookingForm';
import 'dayjs/locale/pl';

dayjs.extend(updateLocale);
dayjs.locale('pl');
dayjs.updateLocale('pl', { weekStart: 1 });

export const BookingPage = () => {
    const [weekStart, setWeekStart] = useState(() => {
        const startOfWeek = dayjs().startOf('week').startOf('day');
        return dayjs().day() === 1 ? startOfWeek : startOfWeek.add(1, 'week');
    });
    const [selectedSlot, setSelectedSlot] = useState(null);
    const [modalFormOpen, setModalFormOpen] = useState(false);
    const [modalAlertOpen, setModalAlertOpen] = useState(false);

    const handleOpen = (slot) => {
        if (slot.isAvailable) {
            setSelectedSlot(slot);
            setModalFormOpen(true);
        }
    };

    const handleModalFormClose = () => {
        setSelectedSlot(null);
        setModalFormOpen(false);
    };

    const handleModalAlertClose = () => {
        setModalAlertOpen(false);
    };

    const weekData = useMemo(() => {
        return _.times(7, (num) => weekStart.add(num, 'day'));
    }, [weekStart]);

    const requestUrl = useMemo(
        () =>
            `${getAvailableSlotsUrl}?${createSearchParams([
                [availableSlotsWeekStartParam, weekData[0].format('YYYY-MM-DD')],
            ])}`,
        [weekData]
    );

    const {
        data: availableSlots,
        error: availableSlotsError,
        loading: availableSlotsLoading,
        refetch: availableSlotsRefresh,
    } = useFetch(requestUrl);

    const slots = useMemo(() => {
        if (!weekData || !availableSlots) return [];
        return weekData.map((day) => {
            const dayAvailability = { from: '9:00', to: '17:00' };
            const [startHour, startMinute] = dayAvailability?.from?.split(':') || [0, 0];
            const startTime = dayjs(day)
                .hour(startHour || 0)
                .minute(startMinute || 0);
            const [endHour, endMinute] = dayAvailability?.to?.split(':') || [0, 0];
            const endTime = dayjs(day)
                .hour(endHour || 0)
                .minute(endMinute || 0);
            const hoursDiff = endTime.diff(startTime, 'hour');
            return hoursDiff > 0
                ? _.times(hoursDiff, (n) => {
                      const dayAndHour = dayjs(startTime).add(n, 'hour');
                      return {
                          day: dayAndHour,
                          isAvailable:
                              _.findIndex(availableSlots || [], (slot) => dayjs(slot).isSame(dayAndHour, 'hour')) !==
                              -1,
                      };
                  })
                : [];
        });
    }, [weekData, availableSlots]);

    function handleOnSubmit(response) {
        if (!response.success) {
            handleModalFormClose();
            return;
        }
        if (response.success.status === 201) {
            availableSlotsRefresh({});
            handleModalFormClose();
        } else {
            setModalAlertOpen(true);
        }
    }

    function getMonthNames() {
        var monthForWeekStart = dayjs(weekStart).format('MMMM YYYY').toString();
        var monthForWeekEnd = dayjs(weekStart).add(6, 'day').format('MMMM YYYY').toString();
        if (monthForWeekStart === monthForWeekEnd) {
            return monthForWeekStart;
        } else {
            return `${monthForWeekStart} / ${monthForWeekEnd}`;
        }
    }

    if (availableSlotsError) throw new Error(availableSlotsError);
    if (availableSlotsLoading) return <Spinner />;
    return (
        <div className={styles.bookings}>
            <div className={styles.bookings_monthnameheader}>
                <div className={styles.bookings_monthnameheader_container}>
                    <div>{getMonthNames()}</div>
                    <div>
                        <ButtonGroup variant="outlined" aria-label="outlined button group">
                            <Button
                                onClick={() => setWeekStart(weekStart.add(-1, 'week'))}
                                disabled={dayjs().startOf('week').isSame(weekStart, 'day')}
                            >
                                <ChevronLeftIcon />
                            </Button>
                            <Button onClick={() => setWeekStart(weekStart.add(1, 'week'))}>
                                <ChevronRightIcon />
                            </Button>
                        </ButtonGroup>
                    </div>
                </div>
            </div>
            <div className={styles.bookings_container}>
                {weekData.map((day, index) => (
                    <div className={styles.bookings_column} key={day.format('ddd')}>
                        <div className={styles.bookings_columnheader}>
                            <div className={styles.bookings_dayName}>{day.format('ddd')}</div>
                            <div className={styles.bookings_day}>{day.format('D')}</div>
                        </div>
                        {slots[index].map((slot) => (
                            <div
                                className={`${styles.bookings_card} ${
                                    slot.isAvailable ? '' : styles.bookings_card__disabled
                                }`}
                                key={slot.day.toISOString()}
                                onClick={() => handleOpen(slot)}
                            >
                                {slot.day.format('HH:mm')}
                            </div>
                        ))}
                    </div>
                ))}
            </div>
            <Modal open={modalFormOpen} onClose={handleModalFormClose} disableEscapeKeyDown disableEnforceFocus>
                <div>
                    <BookingForm slot={selectedSlot} onSubmit={handleOnSubmit} />
                </div>
            </Modal>
            <Dialog
                open={modalAlertOpen}
                onClose={handleModalAlertClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{'Coś poszło nie tak'}</DialogTitle>
                <DialogContent id="alert-dialog-description">
                    Spróbuj ponownie, w przypadku dalszych problemów skontaktuj się z administratorem.
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleModalAlertClose}>Potwierdź</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
