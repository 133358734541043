import React from "react";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Paper from "@mui/material/Paper";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import Stack from "@mui/material/Stack";
import Checkbox from "@mui/material/Checkbox";
import { capitalize } from "@mui/material/utils";

export const EditAvailability = ({ availability, onChange }) => {
  const [selected, setSelected] = React.useState(
    Object.keys(availability).reduce((result, key) => {
      return availability[key] && availability[key].from && availability[key].to
        ? [...result, key]
        : result;
    }, [])
  );
  const isSelected = (name) => selected.indexOf(name) !== -1;
  const hours = [
    "00:00",
    "01:00",
    "02:00",
    "03:00",
    "04:00",
    "05:00",
    "06:00",
    "07:00",
    "08:00",
    "09:00",
    "10:00",
    "11:00",
    "12:00",
    "13:00",
    "14:00",
    "15:00",
    "16:00",
    "17:00",
    "18:00",
    "19:00",
    "20:00",
    "21:00",
    "22:00",
    "23:00",
  ];
  const defaultFrom = "08:00";
  const defaultTo = "19:00";
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleCheckedChange = (event, key) => {
    const selectedIndex = selected.indexOf(key);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, key);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);

    if (selectedIndex === -1) {
      if (availability[key] === null) {
        availability[key] = { from: defaultFrom, to: defaultTo };
      }
    } else {
      availability[key] = null;
    }
  };

  const renderItem = (key, item) => (
    <TableRow hover key={key}>
      <TableCell>
        <Checkbox
          color="primary"
          checked={isSelected(key)}
          onChange={(event) => handleCheckedChange(event, key)}
        />
      </TableCell>
      <TableCell component="th" id={key} scope="row" padding="none">
        {capitalize(key)}
      </TableCell>
      <TableCell>
        {isSelected(key) && (
          <Stack direction="row" alignItems="center">
            <FormControl size="small">
              <Select
                id="from"
                name="from"
                value={item.from}
                onChange={(event) => handleChange(event, key)}
                MenuProps={MenuProps}
              >
                {hours.map((hour) => (
                  <MenuItem key={hour} value={hour}>
                    {hour}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <HorizontalRuleIcon />
            <FormControl size="small">
              <Select
                id="to"
                name="to"
                value={item.to}
                onChange={(event) => handleChange(event, key)}
                MenuProps={MenuProps}
              >
                {hours.map((hour) => (
                  <MenuItem key={hour} value={hour}>
                    {hour}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        )}
      </TableCell>
    </TableRow>
  );

  const renderAvailability = (availability) => (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }}>
        <TableBody>
          {Object.keys(availability).map((key) =>
            renderItem(key, availability[key])
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const handleChange = (event, key) => {
    const {
      target: { name, value },
    } = event;
    onChange({
      ...availability,
      [key]: {
        ...availability[key],
        [name]: value,
      },
    });
  };

  return (
    <>
      <Typography component="h1" variant="h6" color="inherit">
        Set the hours you are available
      </Typography>
      {renderAvailability(availability)}
    </>
  );
};
