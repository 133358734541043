import React, { useMemo } from 'react';
import { useSearchParams, useParams, useLocation, useNavigate, createSearchParams } from 'react-router-dom';
import useFetch from '../../../services/useFetch';
import { Spinner } from '../../../common/Spinner';
import { BookingListItem } from './BookingListItem';
import {
    bookingListLocationQueryParam,
    bookingListDirectionQueryParam,
    bookingListFromQueryParam,
    getBookingListUrl,
} from '../../../constants/apiUrls';
import { bookingsProfileIdSearchParam, bookingsTimelineParam, bookingsTimeline } from '../../../constants/routes';
import { mapTimelineToDirection } from '../../services/mapping';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

export const BookingList = () => {
    const navigate = useNavigate();
    const { search } = useLocation();
    const params = useParams();
    const timeline = params[bookingsTimelineParam] ?? bookingsTimeline.UPCOMING;
    const [searchParams, setSearchParams] = useSearchParams();
    const profileId = searchParams.get(bookingsProfileIdSearchParam);
    const structureRequestUrl = (timeline, profileId) => {
        const params = [
            ...(timeline
                ? [
                      [bookingListDirectionQueryParam, mapTimelineToDirection(timeline)],
                      [bookingListFromQueryParam, new Date().toISOString()],
                  ]
                : []),
            ...(profileId ? [[bookingListLocationQueryParam, profileId]] : []),
        ];
        return params.length ? `${getBookingListUrl}?${createSearchParams(params)}` : getBookingListUrl;
    };
    const requestUrl = useMemo(() => structureRequestUrl(timeline, profileId), [timeline, profileId]);
    const { data: bookings, loading, error } = useFetch(requestUrl);
    const renderItem = (booking) => <BookingListItem key={booking.id} booking={booking} />;
    const onBookingPageChange = (event) => {
        if (event.target.value === '') searchParams.delete(bookingsProfileIdSearchParam);
        setSearchParams(event.target.value ? { [bookingsProfileIdSearchParam]: event.target.value } : searchParams);
    };
    const onTimelineChange = (event, timeline) => {
        if (timeline) {
            navigate({ pathname: `${timeline}`, search });
        }
    };

    if (error) throw error;
    if (loading) return <Spinner />;

    return (
        <>
            <Typography component="h1" variant="h6" color="inherit">
                {timeline === bookingsTimeline.UPCOMING ? 'Upcoming' : 'Past'} bookings
            </Typography>
            <section id="filters">
                <Stack direction="row" spacing={1} mb={1}>
                    <ToggleButtonGroup
                        color="primary"
                        size="small"
                        exclusive
                        value={timeline}
                        onChange={onTimelineChange}
                    >
                        <ToggleButton value={bookingsTimeline.UPCOMING}>Upcoming</ToggleButton>
                        <ToggleButton value={bookingsTimeline.PAST}>Past</ToggleButton>
                    </ToggleButtonGroup>
                    <FormControl size="small">
                        <Select value={profileId || ''} onChange={onBookingPageChange} displayEmpty>
                            <MenuItem value="">All bookings</MenuItem>
                            <MenuItem value="Kraków">Kraków</MenuItem>
                            <MenuItem value="Wrocław">Wrocław</MenuItem>
                            <MenuItem value="Online">Online</MenuItem>
                        </Select>
                    </FormControl>
                </Stack>
            </section>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell>Time</TableCell>
                            <TableCell>Duration</TableCell>
                            <TableCell>Booking</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>{bookings.map(renderItem)}</TableBody>
                </Table>
            </TableContainer>
            <Box mt={1}>
                Found {bookings.length} {bookings.length === 1 ? 'booking' : 'bookings'}
            </Box>
        </>
    );
};
