export const bookingListDirection = {
  FORWARDS: "forwards",
  BACKWARDS: "backwards",
};

export const bookingListLocationQueryParam = "location";
export const bookingListDirectionQueryParam = "direction";
export const bookingListFromQueryParam = "from";

export const availableSlotsProfileIdParam = "profileId";
export const availableSlotsWeekStartParam = "weekStart";

export const getProfileByIdUrl = "admin/profiles";
export const updateProfileUrl = "admin/profiles";
export const getBookingListUrl = "admin/bookings";
export const getBookingDetailsByIdUrl = "admin/bookings";
export const getBookingPageListUrl = "admin/bookingPageList";

export const getAvailableSlotsUrl = "user/slots";
export const bookSlotUrl = "user/book";
