import { bookingsTimeline } from "../../constants/routes";
import { bookingListDirection } from "../../constants/apiUrls";

export const mapTimelineToDirection = (timeline) => {
  switch (timeline) {
    case bookingsTimeline.UPCOMING: {
      return bookingListDirection.FORWARDS;
    }
    case bookingsTimeline.PAST: {
      return bookingListDirection.BACKWARDS;
    }
    default: {
      return "";
    }
  }
};
