import { updateProfileUrl } from "../../constants/apiUrls";

const baseUrl = process.env.REACT_APP_API_BASE_URL;

export async function updateProfile(profile) {
  return fetch(baseUrl + updateProfileUrl + "/" + profile.id, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(profile),
  });
}
