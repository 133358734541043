import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { EditProfile } from "./admin/edit-profile/EditProfile";
import { BookingList } from "./admin/booking/booking-list/BookingList";
import { BookingDetails } from "./admin/booking/booking-details/BookingDetails";
import "./App.scss";
import { BookingPage } from "./user/booking-page/BookingPage";
import * as routes from "./constants/routes";
import Dashboard from "./admin/dashboard/Dashboard";
import { Login } from "./admin/login/Login";
import { authContext as AuthContext } from "./admin/authContext";
import { useState } from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";

function App() {
  const [auth, setAuth] = useState(null);

  return (
    <GoogleOAuthProvider clientId="674489578296-tfhi968dg8fts8oackol5mv0t6ngj0h5.apps.googleusercontent.com">
      <AuthContext.Provider value={auth}>
        <Router>
          <Routes>
            <Route
              path="/"
              index
              element={<BookingPage />}
            />
            <Route
              path={routes.loginPath}
              element={<Login setAuth={setAuth} />}
            />
            <Route element={<Dashboard />}>
              <Route path={routes.dashboardPath} element={<BookingList />}>
                <Route
                  path={`:${routes.bookingsTimelineParam}`}
                  element={<BookingList />}
                />
              </Route>
              <Route
                path={routes.bookingDetailsPath}
                element={<BookingDetails />}
              />
            </Route>
            <Route path={routes.editProfilePath} element={<EditProfile />} />
            <Route path="*" element={<BookingPage />} />
          </Routes>
        </Router>
      </AuthContext.Provider>
    </GoogleOAuthProvider>
  );
}

export default App;
