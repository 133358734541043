import React, { useState } from "react";
import { useSearchParams, useLocation, useNavigate } from "react-router-dom";
import useFetch from "../../../services/useFetch";
import { deleteBooking } from "../../../admin/services/deleteBooking";
import { Spinner } from "../../../common/Spinner";
import { PageNotFound } from "../../../common/PageNotFound";
import { getBookingDetailsByIdUrl } from "../../../constants/apiUrls";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import * as routes from "../../../constants/routes";
import Button from "@mui/material/Button";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import * as dayjs from 'dayjs';

export const BookingDetails = () => {
  const [open, setOpen] = useState(false);
  const handleWarningOpen = () => setOpen(true);
  const handleWarningClose = () => setOpen(false);
  const location = useLocation();
  const navigate = useNavigate();
  const canNavigateBack = !!location.state?.canNavigateBack;
  const [searchParams] = useSearchParams();
  const bookingId = searchParams.get(routes.bookingDetailsIdSearchParam);
  const {
    data: booking,
    loading,
    error,
  } = useFetch(`${getBookingDetailsByIdUrl}/${bookingId}`);
  const handleDeleteBooking = async () => {
    await deleteBooking(bookingId);
    handleWarningClose();
    canNavigateBack
      ? navigate(-1)
      : navigate({ pathname: `/${routes.bookingsPath}` });
  };
  const renderBookingFormDetails = (bookingFormDetails) => (
    <div>
      <Typography component="h1" variant="h6" color="inherit">
        Booking form details
      </Typography>
      <div>First Name: {bookingFormDetails.firstName}</div>
      <div>Last Name: {bookingFormDetails.lastName}</div>
      <div>Email: {bookingFormDetails.email}</div>
      <div>Phone: {bookingFormDetails.phone}</div>
      <div>Age/Grade: {bookingFormDetails.age}</div>
      <div>Event Type: {bookingFormDetails.eventType}</div>
      {bookingFormDetails.city && <div>City: {bookingFormDetails.city}</div>}
      <div>Notes: {bookingFormDetails.description}</div>
    </div>
  );

  if (loading) return <Spinner />;
  if (!booking) return <PageNotFound />;
  if (error) throw error;

  return (
    <div id="detail">
      <Link
        component="button"
        onClick={() =>
          canNavigateBack
            ? navigate(-1)
            : navigate({ pathname: `/${routes.bookingsPath}` })
        }
        underline="hover"
      >
        <NavigateBeforeIcon />
      </Link>
      <Link
        component="button"
        onClick={() =>
          canNavigateBack
            ? navigate(-1)
            : navigate({ pathname: `/${routes.bookingsPath}` })
        }
        underline="hover"
      >
        <Typography variant="body1">Back to bookings</Typography>
      </Link>
      <Paper
        sx={{
          p: 1,
          mb: 1,
        }}
      >
        <Typography component="h1" variant="h6" color="inherit">
          {booking.title}
        </Typography>
        <div>Location: {booking.location}</div>
        <div>
          Starts at:{" "}
          {dayjs(booking.startsAt).format("DD.MM.YYYY HH:mm")}
        </div>
        <div>
          Ends at:{" "}
          {dayjs(booking.endsAt).format("DD.MM.YYYY HH:mm")}
        </div>
        <div>Duration: {booking.durationMinutes} minutes</div>
        <Divider sx={{ my: 1 }} />
        <div>{renderBookingFormDetails(booking)}</div>
        <Divider sx={{ my: 1 }} />
        <Typography component="h1" variant="h6" color="inherit">
          Additional details
        </Typography>
        <div>
          Created at:{" "}
          {dayjs(booking.createdAt).format("DD.MM.YYYY HH:mm")}
        </div>
      </Paper>
      <div align="right">
        <Button
          color="error"
          onClick={handleWarningOpen}
          variant="contained"
          startIcon={<DeleteOutline />}
        >
          Delete
        </Button>
      </div>
      <Dialog
        open={open}
        onClose={handleWarningClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Please confirm you want to delete booking"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleDeleteBooking}>Yes</Button>
          <Button onClick={handleWarningClose} autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
