import { GoogleLogin } from "@react-oauth/google";
import { useContext } from "react";
import { authContext } from "../authContext";
import { Navigate } from "react-router-dom";

export const Login = ({ setAuth }) => {
  const auth = useContext(authContext);

  function handleOnSuccess(credentialResponse) {
    setAuth(credentialResponse);
  }

  function handleOnError(error) {
    setAuth(null);
    console.log(error);
  }

  return auth ? (
    <Navigate to="/dashboard" />
  ) : (
    <GoogleLogin onSuccess={handleOnSuccess} onError={handleOnError} />
  );
};
