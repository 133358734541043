import React, { useState, useContext } from "react";
import { useNavigate, useSearchParams, Navigate } from "react-router-dom";
import useFetch from "../../services/useFetch";
import { Spinner } from "../../common/Spinner";
import { PageNotFound } from "../../common/PageNotFound";
import { getProfileByIdUrl } from "../../constants/apiUrls";
import * as routes from "../../constants/routes";
import { EditProfileForm } from "../profile-form/edit-profile-form/EditProfileForm";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Stack from "@mui/material/Stack";
import theme from "../../theme";
import { authContext } from "../authContext";

export const EditProfile = () => {
  const navigate = useNavigate();
  const goToDashboard = () =>
    navigate({ pathname: `/${routes.dashboardPath}` });
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    data: profile,
    loading,
    error,
  } = useFetch(
    `${getProfileByIdUrl}/${searchParams.get(routes.editProfileIdSearchParam)}`
  );
  const auth = useContext(authContext);

  const section = searchParams.get(routes.editProfileSectionSearchParam);
  const [errorOpen, setErrorOpen] = useState(false);
  const onSectionChange = (section) => {
    setErrorOpen(!canChangeSection);
    if (canChangeSection) {
      setSearchParams({
        [routes.editProfileIdSearchParam]: searchParams.get(
          routes.editProfileIdSearchParam
        ),
        [routes.editProfileSectionSearchParam]: section,
      });
    }
  };
  const [canChangeSection, setCanChangeSection] = useState(true);
  const handleValidityChange = (isValid) => setCanChangeSection(isValid);
  const handleChange = (event, newValue) => onSectionChange(newValue);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorOpen(false);
  };

  if (loading) return <Spinner />;
  if (!profile) return <PageNotFound />;
  if (error) throw error;

  return auth ? (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="absolute">
          <Toolbar>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Button
                component={Link}
                onClick={goToDashboard}
                variant="text"
                color="inherit"
                startIcon={<NavigateBeforeIcon />}
              >
                Back
              </Button>
              <Link
                href={`/${profile.bookingPage.link}`}
                underline="none"
                target="_blank"
                color="inherit"
              >
                {profile.bookingPage.link}
              </Link>
            </Stack>
          </Toolbar>
        </AppBar>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Snackbar
            open={errorOpen}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              onClose={handleClose}
              variant="filled"
              elevation={6}
              severity="error"
              sx={{ width: "100%" }}
            >
              There are errors in this section. Please correct these before you
              continue
            </Alert>
          </Snackbar>
          <Box
            sx={{
              flexGrow: 1,
              bgcolor: "background.paper",
              display: "flex",
            }}
          >
            <Tabs
              orientation="vertical"
              value={section ?? routes.editProfileSection.GENERAL}
              onChange={handleChange}
              sx={{ borderRight: 1, borderColor: "divider" }}
            >
              <Tab value={routes.editProfileSection.GENERAL} label="General" />
              <Tab
                value={routes.editProfileSection.CALENDARS}
                label="Calendar"
                sx={{ display: "none" }}
              />
              <Tab
                value={routes.editProfileSection.AVAILABILITY}
                label="Availability"
              />
            </Tabs>
            <Box
              sx={{
                p: 1,
              }}
            >
              <EditProfileForm
                profile={profile}
                section={section}
                onValidityChange={handleValidityChange}
              ></EditProfileForm>
            </Box>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  ) : (
    <Navigate to={routes.loginPath} />
  );
};
