import { bookSlotUrl } from "../../constants/apiUrls";

const baseUrl = process.env.REACT_APP_API_BASE_URL;

export async function bookSlot(slot) {
  return fetch(`${baseUrl}${bookSlotUrl}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(slot),
  });
}
