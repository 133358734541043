import { useNavigateSearch } from "../../../services/useNavigateSearch";
import Button from "@mui/material/Button";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import * as routes from "../../../constants/routes";
import * as dayjs from 'dayjs';
import "dayjs/locale/pl";
import updateLocale from "dayjs/plugin/updateLocale";
import utc from "dayjs/plugin/utc"
dayjs.extend(updateLocale);
dayjs.extend(utc);
dayjs.locale("pl");
dayjs.updateLocale("pl", { weekStart: 1 });

export const BookingListItem = ({ booking }) => {
  const navigateSearch = useNavigateSearch();
  const goToBookingDetails = () =>
    navigateSearch(
      `/${routes.bookingDetailsPath}`,
      [
        [routes.bookingDetailsIdSearchParam, booking.id],
        [routes.bookingsProfileIdSearchParam, booking.profileId],
      ],
      { canNavigateBack: true }
    );

  return (
    <TableRow hover>
      <TableCell
        component="th"
        scope="row"
        sx={{ cursor: "pointer" }}
        onClick={goToBookingDetails}
      >
        {dayjs(booking.startsAt).format('DD.MM.YYYY')}
      </TableCell>
      <TableCell sx={{ cursor: "pointer" }} onClick={goToBookingDetails}>
        {dayjs(booking.startsAt).format("HH:mm")}
      </TableCell>
      <TableCell sx={{ cursor: "pointer" }} onClick={goToBookingDetails}>
        {booking.durationMinutes} minutes
      </TableCell>
      <TableCell sx={{ cursor: "pointer" }} onClick={goToBookingDetails}>
        {booking.title}
      </TableCell>
      <TableCell align="right">
        <Button
          onClick={goToBookingDetails}
          variant="text"
          startIcon={<EventAvailableIcon />}
        >
          Details
        </Button>
      </TableCell>
    </TableRow>
  );
};
