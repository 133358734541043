export const bookingsTimeline = {
  PAST: "past",
  UPCOMING: "upcoming",
};

export const editProfileSection = {
  GENERAL: "general",
  CALENDARS: "calendars",
  AVAILABILITY: "availability",
};

export const dashboardPath = `dashboard`;
export const editProfilePath = `editProfile`;
export const bookingsPath = `bookings`;
export const bookingDetailsPath = `bookingDetails`;
export const loginPath = "login";

export const bookingsTimelineParam = "timeline";
export const bookingPageLocationParam = "location";

export const bookingsProfileIdSearchParam = "location";
export const bookingDetailsIdSearchParam = "id";
export const editProfileIdSearchParam = "id";
export const editProfileSectionSearchParam = "section";
